import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppCesi = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Zachrante nasi budocnost v evropskych volbách! Tady je postup."
      description="Klikni na linku a pošlete vzkaz přes Whatsapp"
      shareImage="czech"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Zdravýme Vás. Jste evropan a bydlíte v UK? Máte právo volit v evropských volbách v kvetnu.

Jestli pošlete tento vzkas znamým v UK mužeme zastavit Brexit. https://saveourfuture.uk/cestina

Musíte se přihlásit na volby do 7 května. Klikni na https://saveourfuture.uk/simple

Duležitě
Udělejte to co nejdřív. Vás hlas se počitá https://saveourfuture.uk/cestina

Tentokrat váš hlas se bude počitat.`}
    />
    <Interstitial>
      <p>Vyberte si pět nejlepších přátel nebo skupin, abyste mohli poslat zprávu Whatsapp</p>
      <p>Generování zprávy nyní</p>
      <p>Zprávu můžete přizpůsobit v dalším kroku…</p>
    </Interstitial>
    <Disclaimer />
  </Layout>
);

WhatsAppCesi.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppCesi;

export const query = graphql`
  query CesiQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
